@import '../../../../styles/marketplaceDefaults';

.onSiteMain {
  max-width: 1056px;
  margin: 0 1em;
  display: flex;

  flex-direction: column;

  @media (--viewportMedium) {
    width: 50%;
    margin: 0 auto;
  }
}
.onSiteMain > h2 {
  font-family: Noto Sans JP;
  color: var(--successColor);
}
.onSiteHeadings {
  display: flex;
  justify-content: space-between;
  /* flex-wrap: wrap; */
  width: 103%;
  @media (min-width: 375px) {
    flex-wrap: nowrap;
  }
}
.onSiteTitle {
  margin: 0.5em 1em 1em 0;
  background-color: #fff;
  width: 35%;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0.3em 1em;
  border-radius: 10px;
  border: none;
  color: var(--colorGrey900);
  cursor: pointer;
  transition: transform 0.3s, box-shadow 0.3s;
  text-decoration: none !important;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.onSiteTitle:hover {
  transform: translateY(-2px);
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.onSiteTitle:active {
  transform: translateY(1px);
  box-shadow: none;
  border: none;
  color: #fff;
  background-color: var(--successColor);
}

.active {
  color: #fff;
  background-color: var(--successColor);
  border: none;
}

/*css for house rules*/

.btn {
  padding-bottom: 3em;
  margin: 1em 1em 1em 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  border-radius: 10px;
  border: none;
  color: var(--marketplaceColorSecond);
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-2px);
}

.btnText {
  text-decoration: underline;
}

.Gt {
  text-decoration: none;
  margin-left: 0.5em;
}
.shortenedText {
  position: relative;
  margin-right: 2em;
  padding-right: 20px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.ModalText{
  font-size:medium;
}