@import '../../../../styles/marketplaceDefaults';

.howToGetThere {
  overflow: hidden;

  margin: 0 1em;
  @media (--viewportMedium) {
    position: relative;
    width: 50%;
    margin: 0 2em 0 0;
  }
}
.howToGetThere > h2 {
  font-family: Noto Sans JP;
  color: var(--successColor);
}
.shortenedText {
  margin-right: 2em;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 4;
  -webkit-box-orient: vertical;
}

.showFullText {
  margin-right: 2em;
  margin-bottom: 1em;
}
.showFullText .shortenedText::after {
  display: none;
}
.btnImg {
  width: 25px;
  transform: rotate(90deg);
  margin-right: 1em;
}
.btnImgDown {
  width: 25px;
  transform: rotate(90deg);
  margin-right: 1em;
}
.btn {
  padding-bottom: 3em;
  margin: 1em 1em 1em 0;
  background-color: transparent;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 0;
  border-radius: 10px;
  border: none;
  color: var(--marketplaceColorSecond);
  cursor: pointer;
}

.btn:hover {
  transform: translateY(-2px);
}

.btn:active {
  transform: translateY(1px);
  box-shadow: none;
}

.btnText {
  text-decoration: underline;
}

.Gt {
  text-decoration: none;
  margin-left: 0.5em;
}
/* Overlay styles */
.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.6);
  z-index: 9998;
  opacity: 1;
  -webkit-backdrop-filter: blur(5px);
  backdrop-filter: blur(5px);
}
