@import '../../../styles/marketplaceDefaults.css';
@import '../../../styles/customMediaQueries.css';

.container {
  display: flex;
  margin-left: 120px;
  margin-right: 110px;
  @media screen and (min-width: 2200px) {
    margin-left: 120px;
    margin-right: 120px;
  }
  @media screen and (max-width: 2199px) and (min-width: 1920px) {
    margin-left: 130px;
    margin-right: 130px;
  }
  @media screen and (max-width: 1919px) and (min-width: 1620px) {
    margin-left: 150px;
    margin-right: 150px;
  }
  @media screen and (max-width: 1619px) and (min-width: 1400px) {
    margin-left: 60px;
    margin-right: 60px;
  }
  @media screen and (max-width: 1399px) and (min-width: 1280px) {
    margin: 2em calc(2em + 30px) 3em calc(2em + 30px);
  }
  @media screen and (max-width: 1024px) {
    margin: 2em calc(0em + 60px) 3em calc(0em + 60px);
  }
  @media screen and (max-width: 993px) {
    margin: 2em calc(0em + 40px) 3em calc(0em + 40px);
  }
  @media screen and (max-width: 768px) {
    margin: 2em calc(0em + 30px) 5em calc(0em + 30px);
  }
  @media screen and (max-width: 600px) {
    flex-wrap: wrap;
    margin-left: 2em;
    margin-right: 2em;
  }
}
.sectionh2 {
  text-align: center;
  @media (--viewportMedium) {
    text-align: left;
  }
}
.card {
  flex: 1;
  background-color: white;
  border-radius: 8px;
  padding: 16px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  margin-right: 16px;
  @media screen and (max-width: 1920px) {
    height: 800px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media screen and (max-width: 1400px) {
    height: 755px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media screen and (max-width: 1280px) {
    height: 750px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media screen and (max-width: 600px) {
    margin-bottom: 20px;
    max-width: 460px;
  }
  @media screen and (max-width: 550px) {
    margin-bottom: 20px;
    max-width: 440px;
  }
  @media screen and (max-width: 520px) {
    margin-bottom: 20px;
    max-width: 420px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
  @media screen and (max-width: 480px) {
    margin-bottom: 20px;
    max-width: 380px;
    max-height: 650px;
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }
}

.card:last-child {
  margin-right: 0;
}

.cardImage {
  border-radius: 8px 8px 8px 8px;
  width: 100%;
  height: 60%;
  @media screen and (max-width: 1400px) {
    width: 100%;
    height: 50%;
  }
  @media screen and (max-width: 600px) {
    width: 100%;
    height: 50%;
  }
}

.title {
  padding-top: 10px;
  font-size: 22px;
  margin-top: 23px;
  color: var(--successColor);
  line-height: 1.2;
  min-height: 40px;
  @media screen and (max-width: 950px) {
    padding-top: 0px;
    margin-top: 0px;
  }
}

.description {
  margin-top: 8px;
  font-weight: 500;
  font-size: 16px;
  line-height: 22px;
  letter-spacing: -0.1px;
  margin-bottom: 12px;
  @media screen and (max-width: 599px) {
    margin-top: 0px;
    min-height: 150px;
  }
  @media screen and (max-width: 400px) {
    margin-top: 0px;
    min-height: 180px;
  }
  @media screen and (min-width: 600px) and (max-width: 950px) {
    margin-top: 0px;
    min-height: 260px;
  }
  @media screen and (min-width: 1000px) {
    min-height: 240px;
  }
  @media screen and (min-width: 1900px) {
    min-height: 130px;
  }
}

.button {
  width: 225px;
  border: none;
  color: #fff;
  background-color: #eb7242;
  padding: 1em 1.5em;
  transition: all ease-in-out 0.1s;
  cursor: pointer;
  text-align: center;
  border-radius: 11px;
  z-index: 0;
  box-shadow: 0px 4px 15px rgba(235, 114, 66, 0.4);

  @media screen and (min-width: 2200px) {
    position: static;
    margin-top: 15px;
  }
  @media screen and (max-width: 1920px) {
    position: static;
    margin-top: 15px;
  }
  @media screen and (max-width: 1300px) {
    position: static;
    margin-top: 15px;
  }
  @media screen and (max-width: 1280px) {
    position: static;
    margin-top: 0;
  }
  @media screen and (max-width: 600px) {
    position: relative;
    margin-top: 15px;
    left: 45%;
  }
  @media screen and (max-width: 500px) {
    left: 10%;
  }
}
.button:hover {
  background-color: #353535;
}

.buttonLeft {
  /*delete the code from 176 - 181 and activate the rest again*/
  position: relative;
  bottom: -30px;
  @media screen and (max-width: 1300px) and (min-width: 800px) {
    bottom: -60px;
  }
  @media screen and (max-width: 799px) and (min-width: 700px) {
    bottom: -75px;
  }
}

.buttonRight {
  @media screen and (max-width: 424px) {
    position: relative;
    bottom: -30px;
    margin-top: 10px;
  }
  @media screen and (min-width: 425px) {
    position: relative;
    bottom: -30px;
    margin-top: 12px;
  }
  @media screen and (min-width: 768px) {
    position: relative;
    bottom: -70px;
    margin-top: 0px;
  }
  @media screen and (min-width: 1024px) {
    position: relative;
    bottom: -82px;
    margin-top: 0;
  }
  @media screen and (min-width: 1280px) {
    position: relative;
    bottom: -54px;
    margin-top: 0;
  }
  @media screen and (min-width: 1400px) {
    position: relative;
    bottom: -42px;
    margin-top: 0;
  }
}
.sectionTitle {
  font-size: 2rem;
  padding: 0 0 0 0;
  margin-left: 120px;
  margin-bottom: 50px;
  color: var(--successColor);

  @media screen and (min-width: 2200px) {
    margin-left: 120px;
  }
  @media screen and (max-width: 2200px) and (min-width: 1920px) {
    margin-left: 125px;
  }
  @media screen and (max-width: 1919px) and (min-width: 1620px) {
    margin-left: 150px;
  }
  @media screen and (max-width: 1620px) and (min-width: 1401px) {
    margin-left: 100px;
  }
  @media screen and (max-width: 1400px) and (min-width: 821px) {
    margin-left: 60px;
  }
  @media screen and (max-width: 820px) and (min-width: 601px) {
    margin-left: 35px;
  }
  @media screen and (max-width: 600px) {
    margin-left: 1em;
  }
}
.sectionContainer {
  max-width: 1600px;
  @media screen and (min-width: 2200px) {
    max-width: 1600px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }
  @media screen and (min-width: 1600px) and (max-width: 2200px) {
    max-width: 1650px;
    margin: 0 auto;
    justify-content: center;
    align-items: center;
  }
  @media screen and (max-width: 1600px) {
    margin-top: 30px;
    margin-bottom: 50px;
  }
  @media screen and (max-width: 450px) {
    margin-bottom: 20px;
    margin-top: -20px;
  }
}
@media screen and (max-width: 450px) {
  .card {
    width: 100%;
    margin-right: 0;
  }
}
.slider-container {
  position: relative;
  margin: 0 auto;
  width: 100vw;
  transform: translateX(-3%);
  @media screen and (max-width: 600px) {
    left: 50%;
    transform: translateX(-39%);
  }
}

.slider-cards {
  position: relative;
  width: 110%;
  height: 600px;
  overflow: hidden;
  margin: 0 auto;
  padding-bottom: 0;
  @media screen and (max-width: 600px) {
    height: 650px;
  }
}

.slide {
  position: absolute;
  width: 100%;
  height: 100%;
  display: flex;
  background-color: transparent;
  object-fit: cover;
  border-radius: 15px;
  opacity: 0.3;
  margin: 0 auto;
  transition: opacity 600ms cubic-bezier(0.25, 0.46, 0.45, 0.84), transform 600ms ease;
}

.slide-img {
  width: 100%;
  height: 250px;
  border-radius: 15px 15px 0 0;

  object-fit: cover;
}

.slider-buttons {
  pointer-events: none;
  position: absolute;
  display: flex;
  top: 47%;
  left: 50%;
  transform: translateY(-50%) translateX(-50%);
  z-index: 4;
  width: 100%;
  justify-content: space-between;
  @media screen and (max-width: 600px) {
    transform: translateX(-59.5%);
  }
}
.slider-button {
  pointer-events: auto;
  padding: 0.5em 0.8em;
  background: white;
  border: 1px solid #eb7242;
  border-radius: 50%;
  margin: 1em;
  width: 45px;
  color: #eb7242;
}

.slider-button:hover {
  transform: scale(1.03);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  cursor: pointer;
}

.slider-button:active {
  transform: scale(0.92);
  box-shadow: 0 0 10px rgba(255, 255, 255, 0.4), 0 0 20px rgba(255, 255, 255, 0.1);
}
.slider-title {
  font-size: var(--marketplaceLPFontsize);
  text-align: left;
  color: #1c7881;
  text-align: left;
}
.slide-text {
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  padding: 2em 2em 0 2em;
  grid-gap: 5px;
  grid-gap: 5px;
  gap: 10px;
  height: 350px;
  justify-content: flex-start;
}

.sliderHeader {
  display: block;
  max-width: 1400px;
  margin: 0 auto;
  margin-top: 1em;
  @media (max-width: 1024px) {
    margin: 0 1em;
    margin-top: 1em;
  }
  @media (min-width: 1024px) {
    margin: 0 5em;
    margin-top: 1em;
  }
  @media (min-width: 1300px) {
    margin: 0 7em;
    margin-top: 1em;
  }
  @media (min-width: 1920px) {
    margin: 0 auto;
    margin-top: 1em;
  }
}

.sliderSubtitle {
  margin-top: -0.125em;
  font-size: 1em;
  font-weight: 400;
  text-align: center;
  @media (--viewportMedium) {
    text-align: left;
  }
}

.slide-headline {
  text-align: justify;
  font-size: 1rem;
}

.slide-subHeadline {
  color: #1c7881;

  font-size: 0.8rem;

  position: absolute;
  bottom: 0;
  padding: 1em 2em 1em 0;
}

.link-name {
  color: #1c7881;
}
.link-name:hover {
  color: #eb7242;
}

/*slide transformation */

.slide-0 {
  transform: translateX(-100%);
  opacity: 0;
}
.slide-1 {
  transform: translateX(0%);
  opacity: 1;
}

.slide-2 {
  transform: translateX(105%);
  opacity: 1;
}

.slide-3 {
  transform: translateX(210%);
  opacity: 1;
}
.slide-4 {
  transform: translateX(315%);
  opacity: 1;
}

.slide-5 {
  transform: translateX(420%);
  opacity: 1;
}
.hidden {
  display: none;
}

@media screen and (min-width: 340px) {
  .slider-cards {
    display: block;
    width: 270vw;
    margin-bottom: 2em;
    height: 560px;
  }

  .slide {
    position: absolute;
    width: 30%;
    height: 100%;
    display: flex;
    background-color: transparent;
    object-fit: cover;
  }
  .slider-button {
    margin: 1em;
  }
  .slide-headline {
    text-align: justify;
    font-size: 1.3rem;
  }
  .slide-img {
    height: 300px;
  }
}

@media screen and (min-width: 768px) {
  .slider-cards {
    display: block;
    width: 140%;
    margin-bottom: 2em;
    height: 560px;
  }

  .slide {
    position: absolute;
    width: 30%;
    height: 100%;
    display: flex;
    background-color: transparent;
    object-fit: cover;
  }

  .slider-buttons {
    top: 40%;
    left: 50%;
  }
  .slider-button {
    margin: 2em;
  }

  .slider-title {
    text-align: left;
  }

  .slide-img {
    width: 100%;
    height: 225px;
  }

  .slide-text {
    justify-content: flex-start;
    gap: 10px;
  }
  .slide-headline {
    text-align: left;
    font-size: 1rem;
    margin: 0 auto;
  }
}

@media screen and (min-width: 1024px) {
  .slider-container {
    margin-top: 10px !important;
  }

  .slider-cards {
    display: block;
    width: 100%;
    margin-bottom: 2em;
    height: 560px;
  }
  .slide {
    position: absolute;
    width: 45%;
    height: 80%;
    display: flex;
    background-color: transparent;
    object-fit: cover;
  }

  .slider-buttons {
    top: calc(50% - 100px);
    left: 50%;

    @media (--viewportXLarge) {
      left: 50%;
    }
  }

  .slide-img {
    width: 265px;
    height: 100%;
    border-radius: 15px 0 0 15px;
  }

  .slide-text {
    padding-top: 0em;
    justify-content: flex-start;
    gap: 10px;
  }
  .slide-headline {
    font-size: 1rem;
  }
  .slide-subHeadline {
    font-size: 1rem;
  }
}

@media screen and (min-width: 1300px) {
  .slider-container {
    margin-top: 10px !important;
    /*     background-color: red; */
    outline: none;
    max-width: 1400px;
 /*    width: 110%; */
  }

  .slider-cards {
    display: block;
    max-width: 1400px;
    width: 100%;
    margin-bottom: 2em;
    height: 590px;
  }

  .slider-title {
    font-size: var(--marketplaceLPFontsize);
  }
  .slide {
    position: absolute;
    width: 334px;
    height: 534px;
    display: flex;
    background-color: transparent;
    object-fit: cover;
  }

  .slide-img {
    width: 265px;
    height: 100%;
    border-radius: 15px 0 0 15px;
  }

  .slide-text {
    padding-top: 0em;
    justify-content: flex-start;
    gap: 10px;
  }
  .slide-subHeadline {
    font-size: 1.2rem;
  }
  .slider-buttons {
    width: 90%;
    @media (--viewportXLarge) {
      width: 66%;
    }
  }
}
/* @media screen and (min-width: 1900px) {
  .slider-container {
    margin-top: 120px !important;
    margin-bottom: -50px !important;
    background-color: transparent;
    outline: none;
  }
  .slider-cards {
    display: block;
    width: 110%;
    margin-bottom: 2em;
    height: 534px;
  }

  .slide {
    position: absolute;
    width: 30%;
    height: 90%;
    display: flex;
    background-color: transparent;
    object-fit: cover;
  }

  .slider-title {
    font-size: var(--marketplaceLPFontsize);
    margin-bottom: 1em;
    margin-left: 15px;
  }
  .slider-buttons {
    top: 45%;
    left: 50%;
  }

  .slide-img {
    width: 300px;
    height: 100%;
    border-radius: 15px 0 0 15px;
  }

  .slide-text {
    padding-top: 0em;
    justify-content: flex-start;
    gap: 50px;
  }
  .slide-subHeadline {
    font-size: 1.2rem;
  }
  .slide-headline {
    font-size: 1.3rem;
    line-height: 1.5;
  }
} */

.outerWrapper {
  width: 334px;
  height: 534px;
  background-color: transparent;
}

.innerWrapper {
  background-color: white;
  padding-top: 1px;
  margin: 10px;
  border-radius: 15px;
  height: 535px;
  position: relative;
  cursor: pointer;
  box-shadow: 0 4px 8px 3px rgba(0, 0, 0, 0.2);

  &:hover {
    transform: scale(1.01);
    box-shadow: 0 2px 6px 1px rgba(0, 0, 0, 0.35);
  }

  &:active {
    transform: scale(0.98);
  }
}

.imageWrapper {
  cursor: pointer;
  position: relative;
  max-width: 93%;
  height: 400px;
  margin: 11px;
  border-radius: 13px;
  overflow: hidden;
}
.imageWrapper::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 13px;
  background: linear-gradient(
    to bottom,
    rgba(0, 0, 0, 0.7),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0),
    rgba(0, 0, 0, 0.4),
    rgba(0, 0, 0, 0.8)
  );
  z-index: 2;
  pointer-events: none; /* Ensures the overlay doesn't interfere with clicks */
}

.address {
  position: absolute;
  z-index: 3;
  color: white;
  font-size: 1rem;
  font-weight: 300;
  right: 19px;
  top: 15px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
}

.daysLabel {
  position: absolute;
  font-family: Noto Sans JP;
  font-size: 1.1em;
  width: 70%;
  font-weight: 500;
  z-index: 3;
  color: white;
  left: 18px;
  margin-right: 15px;
  bottom: 30px;
  text-shadow: 1px 1px 2px rgba(0, 0, 0, 0.5);
  max-width: fit-content;
  overflow: hidden;
}

.cardImg {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 13px;
  z-index: 1;
  cursor: pointer;
}

.projectTitleWrapper {
  display: flex;
  flex-direction: row;
  gap: 0.6em;
  justify-content: space-between;
  align-items: center;

  padding-top: 10px;
  padding-left: 20px;
  max-width: 96%;
}

.projectName {
  font-weight: 400;
  font-size: 0.75em;
  color: black;
  line-height: 1.5em;
}

.priceWrapper {
  display: flex;
  justify-content: flex-start;
  align-items: left;
  flex-direction: column;
  width: 200px;
  padding-top: 10px;
  height: 70px;
}

.priceText {
  font-size: 0.7em;
}

.price {
  font-weight: 600;
  font-size: 1.125em;
  padding-top: 2px;
  height: 60px;
  color: #1c7881;
}

.ratings {
  display: flex;
  position: absolute;
  bottom: 1.2em;
  right: 10px;
}

@media (max-width: 600px) {
  .daysLabel {
    width: 80%;
  }
}
