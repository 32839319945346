.cancellationContainer {
  margin: 0 auto;
}

.title {
  margin-bottom: 10px;
}

.policyContent {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.policyList {
  list-style: none;
  padding: 0;
  margin: 0;
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.policyItem {
  line-height: 1.5;
}

.termsLink {
  text-decoration: none;
  display: inline-block;
  margin-left: 4px;
}

.termsLink:hover {
  text-decoration: underline;
}
