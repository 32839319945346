@import '../../../../styles/marketplaceDefaults.css';
@import '../../../../styles/customMediaQueries.css';
.videoSectionWrapper {
  margin: 1em;
  position: relative;
  z-index: 1;
  @media (--viewportSmall) {
    margin: 1em 0;
  }
}
.videoSectionWrapper > h2 {
  font-family: Noto Sans JP;
  color: var(--successColor);
}

.videoPlayer {
  margin-top: 40px;
  display: flex;
  justify-content: space-between;
  flex-direction: row;
  align-items: center;
  @media (--viewportSmall) {
    width: 100%;

    margin: 1em 0;
  }
}

.playerStyles {
  width: 80%;
  height: 609px;
}
