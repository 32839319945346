@import '../../../../../styles/marketplace.css';
/* Overlay styles */

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 9998;
  opacity: 1;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

/* Modal styles */
.modal {
  position: fixed;
  top: 6%;
  left: 0;
  width: 100%;
  height: 95%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow: hidden;
  @media screen and (max-width: 1300px) {
    top: 9%;
    height: 92%;
  }
}

.modalHost {
  position: fixed;
  top: 6%;
  left: 0;
  width: 100%;
  height: fit-content;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
  overflow: hidden;
  @media (--viewportMedium){
    top: 20%;
    height: fit-content;
  }
}

.modalContent {
  background-color: #fff;
  padding: 0.5em 25px 7em 35px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  max-width: 700px;
  height: 80%;
  margin: auto 1em;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: auto;
  -ms-overflow-style: auto;

  justify-content: flex-start;
  overflow: auto;
  @media (--viewportMedium) {
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer and Edge */
    width: 800px;
  }
}

/* Modal is being as the HostModal*/
.modalContentHost {
  background-color: #fff;
  padding: 0.5em 25px 2em 35px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  max-width: 700px;
  height: 80%;
  margin: auto 1em;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: auto;
  -ms-overflow-style: auto;

  justify-content: flex-start;
  overflow: auto;
  @media (--viewportMedium) {
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer and Edge */
    width: 800px;
  }
}

/*If the Modal is being opened in the Reviews Component*/
.modalContentReviews {
  background-color: #fff;
  padding: 0.5em 25px 2em 35px;
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.3);
  display: flex;
  flex-direction: column;
  max-width: 800px;
  height: 80%;
  margin: auto 1em;
  overflow: auto;
  overflow-x: hidden;
  scrollbar-width: auto;
  -ms-overflow-style: auto;

  justify-content: flex-start;
  overflow: auto;
  @media (--viewportMedium) {
    scrollbar-width: none; /* Hide the scrollbar for Firefox */
    -ms-overflow-style: none; /* Hide the scrollbar for Internet Explorer and Edge */
    width: 800px;
  }
}

.modalContent::-webkit-scrollbar {
  @media (--viewportMedium) {
    display: none; /* Hide the scrollbar for Chrome and Safari */
  }
}
.closeButton {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  z-index: 10000;
  border: none;
  color: #fff;
  cursor: pointer;
}
.closeButtonImg {
  width: 40px;
}

.closeButton:hover {
  transform: translateY(-2px);
}

.closeButton:active {
  transform: translateY(1px);
  box-shadow: none;
}

/*specific added classes */

.sliderModal {
  width: 100%;
  height: 100vh;
  top: 0;
  position: fixed;
  max-width: none;
  max-height: none;
  background-color: rgba(0, 0, 0, 0.6);
  padding: 0.5em 0 0 0;
  border-radius: 0;
  overflow-y: auto;
  z-index: 1;
  margin: 0;
}
.galleryModal {
  max-width: 1080px;
  padding: 1em 4em;
  margin: 0 auto;
  max-height: 100vh;
  background-color: transparent;
  box-shadow: none;
  overflow: hidden; /* Hides the scrolling bar */
  overflow-y: scroll; /* Enables vertical scrolling */
  -webkit-overflow-scrolling: touch;
  overflow: auto;
}

.buttonGallery {
  color: #fff;
  position: absolute;
  top: -30px;
  right: 0;
  display: flex;
  margin-right: 1em;
  align-self: flex-end;
  padding: 1em 0.5em;
  @media (--viewportMedium) {
    top: 0;
  }
}
.buttonActivities {
  position: relative;
  color: black;
  top: 0em;
  right: -1em;
  @media (--viewportMedium) {
    position: fixed;
    right: calc(50% - 350px);
    top: unset;
    background-color: white;
    border-radius: 30%;
  }
}

.buttonHowToGetThere {
  position: relative;
  color: black;
  top: 0em;
  right: -1em;
  @media (--viewportMedium) {
    position: fixed;
    right: calc(50% - 350px);
    top: unset;
    background-color: white;
    border-radius: 30%;
  }
}

.buttonSlider {
  position: absolute;
  color: white;
  top: 0;
  right: 1em;
}

.btnImage {
  filter: invert(100%);
  -webkit-filter: invert(100%);
  -moz-filter: invert(100%);
}

.btnReviews {
  position: fixed;
  right: -1%;
  z-index: 9999;
}
.btnReviews span {
  display: none;
}

@media (min-width: 768px) {
  .btnReviews {
    position: fixed;
    right: calc(50% - 400px);
  }
}
